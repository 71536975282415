.amplify-form-section input {
    /* Change border color */
    border-color: #dac212;
}

.amplify-button--primary {
    background-color: #F666EF;
}

.amplify-tabs__item--active {
    color: black;
    border-top: 1mm solid #F666EF;
}

.amplify-tabs__item--active:focus {
    outline: none;
}

.amplify-tabs__item:hover {
    color: black
}